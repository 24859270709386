import React from "react"

import './App.css';

function Header() {
    return (
        <div className="Header">
            <header>
                <img src="http://www.pngall.com/wp-content/uploads/2016/05/Trollface.png"
                alt="HeHEHe"/>
            </header>
        </div>
    );
}

export default Header;
